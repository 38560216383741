var speakersBureauFunctionality = function() {

	$(document).ready(function (){
		setupAjaxLoadingAnimation();
		var dateField = $('.ce__register-date-text-input');
		if (dateField && dateField.attr('type') === 'text') {
			$('.ce__register-date-text-input').datepicker();
		}
	});

	$(document).on('click', '.speakers-bureau__navbar-mobile-selected', function() {
		var nationDisplay = $('.speakers-bureau__navbar-mobile-options').css('display');
		if (nationDisplay === 'none')  {
		  $('.speakers-bureau__navbar-mobile-options').css('display', 'block').animate({opacity:1}, 500);
		} else {
		  $('.speakers-bureau__navbar-mobile-options').animate({opacity:0}, 500).css('display', 'none');
		}	  
	});
	
	$(document).on('click', '.speakers-bureau__events-list__list__item__reminder span.close-recap', function() {
		var parentElement = $(this).parent();
		parentElement.hide();
		parentElement.parent().css('margin-top','0px');
	});

	$(document).on('click', 'p.speakers-bureau__request-edit-btn', function() {
		var requestSection = $(this).parents().attr('id');
		requestSection = '#' + requestSection;

		$(requestSection).removeClass('speakers-bureau__request-saved');
		$('.speakers-bureau__request-pod-accordion').hide();
		$(requestSection).find('.speakers-bureau__request-pod-accordion').show();
	});

	$(document).on('click', '[data-cta]', function () {
        var cta = $(this);
        if (cta.is('[data-cta-show]')) {
            var showElementData = cta.data('cta-show');
            var showElement = showElementData === 'self'
                ? $(this) : showElementData === 'parent'
                ? $(this).parent() : $(showElementData);
            if (showElement != null) {
                showElement.show();
            }
        }
        if (cta.is('[data-cta-hide]')) {
            var hideElementData = cta.data('cta-hide');
            var hideElement = hideElementData === 'self'
                ? $(this) : hideElementData === 'parent'
                ? $(this).parent() : $(hideElementData);
            if (hideElement != null) {
                hideElement.hide();
            }
        }
        if (cta.is('[data-cta-listappend]')) {
            var appenderObject = executeAppender(cta, parseInt(cta.data('index')), false);
            if (appenderObject != null && appenderObject.success) {
                cta.data('index', appenderObject.index.toString());
            }
        }
        if (cta.is('[data-cta-modal]')) {
			var modalContainer = cta.data('cta-modal');
			$(modalContainer).css('display', 'flex');
		}
		if (cta.is('[data-cta-remove]')){
			var elementsToRemove = getListOfElements(cta.data('cta-remove'));
			$.each(elementsToRemove, function (i, elementToRemove){
				elementToRemove.remove();
			});
		}
		if (cta.is('[data-cta-inputupdater]')){
			inputUpdater(cta);
		}
	});

	$(document).on('keypress', '[data-numericonly-whole]', function(event){
		var isNumber = event.charCode >= 48 && event.charCode <= 57;
		return isNumber;
	});
	
    // Form reset
    $(document).on('click', '[data-form-reset]', function () {
        var element = $(this);
        var formResetData = element.data('form-reset');
        var resetForm = formResetData === 'self'
            ? element
            : formResetData === 'parent'
            ? element.parent()
            : $(formResetData);
        if (resetForm != null) {
            resetForm.find(':input:not([type=submit],[type=reset],[type=button])')
                .each(function () {
                    $(this).val('');
                });
        }
    });
	
	$(document).on('click', '[data-action]', function () {
		var button = $(this);
		executeAction(button);
	});
	
		
	$(document).on('change', '[data-imagepreview]', function(){
		var element = $(this);
		previewImage(element);
	});

	$(document).on('change', '.request-file-input', function (){
		var input = $(this);
		var label = input.next();
		var labelVal = label.html();
		var fileUploaded = label.next();

		var fileName = ( input.data('caption') || '' ).replace( '{fileName}', input[0].files[0].name );
		if (fileName) {
			label.html(fileName);
			label.css({'color':'#1d1d1d','background':'white'});
		} else {
			label.html(labelVal);
		}
	});


	// Serialize inputs and create FormData object
	var serializeInputs = function (parentElement) {
		var formData = new FormData();
		var serializedInputs = parentElement.find(':input').serializeArray();
		for (var x = 0; x < serializedInputs.length; x++) {
			formData.append(serializedInputs[x].name, serializedInputs[x].value);
		}
		var fileUploadCount = parentElement.find('input[type=file]').length;
		for (var y = 0; y < fileUploadCount; y++) {
			var fileUpload = parentElement.find('input[type=file]').get(y);
			if (fileUpload != undefined) {
				var files = fileUpload.files;
				var propName = $(fileUpload).attr('name') == undefined ? 'Files' : $(fileUpload).attr('name');
				for (var i = 0; i < files.length; i++) {
					formData.append(propName, files[i]);
				}
			}
		}
		return formData;
	}
	
	// Create ajax loading animation
	var setupAjaxLoadingAnimation = function () {
		var $loading = $('#loadingDiv').hide();
		$(document).ajaxStart(function () {
			$loading.fadeIn(300);
		})
		.ajaxStop(function () {
			$loading.fadeOut(300);
		});
	}

	// Ajax function for POSTing data
	var ajaxPost = function (url, elements, serializedData, onSuccess, onComplete) {
		$.ajax({
			url: url,
			method: 'POST',
			type: 'POST',
			contentType: false,
			processData: false,
			data: serializedData,
			success: function (data) {
				if (!data.Success) {
					console.log("An error occurred. " + data.Data);
				} else {
					if (onSuccess != null) {
						onSuccess();
					}
				}
				$.each(elements, function (index, value) {
					value.html(data.Data[index]);
				});
			},
			error: function (data) {
				var message = 'Unable to post to ' + url + ' ';
				if (data != undefined) {
					message = message + data.statusText;
				}
				alert(message);
			},
			complete: function () {
				if (onComplete != null) {
					onComplete();
				}
			}
    })};

	// Get list of elements
	var getListOfElements = function (elementIds, delimiter) {
		delimiter = !delimiter ? ';' : delimiter;
		var strings = getListOfStrings(elementIds, delimiter);
		if (strings == null) {
			return null;
		}
		var elementArray = [];
		$.each(strings, function (index, value) {
			elementArray[index] = $(value);
		});
		return elementArray;
	}
	
	// Get list of strings
	var getListOfStrings = function (strings, delimiter) {
		delimiter = !delimiter ? ';' : delimiter;
		if (strings == null) {
			return null;
		}
		var stringsArray = strings.split(delimiter);
		return stringsArray;
	}

	// Execute Action
	var executeAction = function (element) {
		var url = element.data('action');
		var delimiter = element.data('delimiter');
		var replaceableElements = getListOfElements(element.data('replaceable-elements'), delimiter);
		var serializedData = serializeInputs($(element.data('serialize-container')));
		var onSuccess = function () {
			if (element.is('[data-action-onsuccess]')) {
				var action = eval(element.data('action-onsuccess'));
				if (typeof action == 'function') {
					action();
				}
			}
			if (element.is('[data-cta-show]')) {
				$(element.data('cta-show')).show();
			}
		};
		ajaxPost(url, replaceableElements, serializedData, onSuccess, null);
	}

	var previewImage = function (element) {
		var previewElementValue = element.data('imagepreview');
		if (!previewElementValue){ return; }

		var previewElement = $(previewElementValue);
		var input = element[0];
		if (!input){ return; }

		if (input.files && input.files[0]) {
			var reader = new FileReader();
			reader.onload = function (e) {
				previewElement.attr('src', e.target.result);
			}
			reader.readAsDataURL(input.files[0]);
		}
	}
	
	// Execute Appender
	var executeAppender = function (element, index) {
		var delimiter = element.data('delimiter');
		var templates = getListOfElements(element.data('template-container'), delimiter);
		var appendTos = getListOfElements(element.data('template-appendto'), delimiter);
		var indexPattern = element.data('template-indexpattern');
		var copyAttributeFrom = getListOfStrings(element.data('template-copyattribute-from'), delimiter);
		var copyAttributeTo = getListOfStrings(element.data('template-copyattribute-to'), delimiter);
		if (templates == null || appendTos == null || indexPattern == null || index == null || copyAttributeFrom == null || copyAttributeTo == null) {
			console.log('Elements are missing from the page. Template: ' + templates + '; AppendTo: ' + appendTos
				+ '; SerializeContainer: ' + serializeContainer + '; IndexPattern: ' + indexPattern + '; Index: ' + index);
			return { success: false, index: 0 };
		}		
		if (templates.length !== appendTos.length){ 
			console.log('The number of templates do not match the number of appendable elements. Template: ' 
				+ templates.length + '; AppendTo: ' + appendTos.length);
			return {success: false, index: 0} 
		};		
		if (copyAttributeFrom.length !== copyAttributeTo.length){ 
			console.log('The number of copyattributes do not match. Copy From: ' 
				+ copyAttributeFrom.length + '; Copy To: ' + copyAttributeTo.length);
			return {success: false, index: 0} 
		};
		$.each(templates, function (i, template) {
			var clone = template.clone();
			$.each(copyAttributeFrom, function (x, copyFrom){
				if (!clone.is('[' + copyFrom + ']')){
					return true;
				}
				var patternRegEx = new RegExp(indexPattern, 'g');
				var copyFromValue = clone.attr(copyFrom);
				clone.removeAttr(copyFrom);
				clone.attr(copyAttributeTo[x], copyFromValue.replace(patternRegEx, index));
			});
			appendTos[i].append(clone);
		});
		return { success: true, index: index + 1 };
	}

	var inputUpdater = function (element){
		if (!element) { return false; }
		
		var delimiter = element.data('delimiter');
		var copyValues = getListOfStrings(element.data('template-copyvalue'), delimiter);
		var copyValueTos = getListOfElements(element.data('template-copyvalueto'), delimiter);
		if (copyValues.length != copyValueTos.length){
			console.log('The number of copyvalues do not match. Copy values: ' 
				+ copyValues.length + '; Copy To: ' + copyValueTos.length);
			return false;
		}
		$.each(copyValueTos, function (i, copyValueTo)
		{
			var valueContainer = $(copyValues[i]);
			if (valueContainer && valueContainer.length > 0){
				copyValueTo.val(valueContainer.html());
			} else {
				copyValueTo.val(copyValues[i]);
			}
		});
	}
};

module.exports = speakersBureauFunctionality;