var carouselFunctionality = function(id, type){
  var carouselItems = $(id);
  var carouselLength = carouselItems.length;
  var carouselIndex = 1;
  var carouselCurrent = getCarousel(0);
  var carouselAnimating = false;
  var windowSize = $(window).width();
  var mobile = /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  var iPad = /iPad/i.test(navigator.userAgent);
  var carouselDelay = 10000;
  var carouselTimer;

  var sliderPage = 0;

  $('.home .content__slide').each(function() {
    sliderPage = sliderPage + 1;
    var slideNumber = 'contentSlideNumber_' + sliderPage;
    $(this).attr('id', slideNumber);
  });

  $('.home .content__slide .content__slide-option').click(function() {
    if(!carouselAnimating){
      carouselAnimating = true;
      var currentSlide = parseInt($(this).parents().eq(1).attr('id').replace('contentSlideNumber_',''));//$('.home .content__slide').attr('id');
      var optionClicked = parseInt($(this).attr('id').replace('slide-option_',''));
      if (currentSlide > optionClicked) {
        var optionClickedLeft = 0 - currentSlide;
        var goToCarousel = optionClickedLeft + optionClicked;
      } else {
        var goToCarousel = optionClicked - currentSlide;
      }
      var carouselNext = getCarousel(goToCarousel);
      carouselNext.animate({left: windowSize}, 0);
      carouselNext.animate({left: 0}, 400);
      carouselCurrent.animate({left: '-=' + windowSize}, 400, function(){
        carouselAnimating = false;

        if(!mobile){
          clearTimeout(carouselTimer);
          carouselTimer = setTimeout(navRight, carouselDelay);
        }
      });
      carouselCurrent = carouselNext;
    }
  });

  function navLeft(){
    if(!carouselAnimating){
      carouselAnimating = true;
      var carouselNext = getCarousel(-1);
      carouselNext.animate({left: -windowSize}, 0);
      carouselNext.animate({left: 0}, 400);
      carouselCurrent.animate({left: '+='+windowSize}, 400, function(){
        carouselAnimating = false;

        if(!mobile){
          clearTimeout(carouselTimer);
          carouselTimer = setTimeout(navRight, carouselDelay);
        }
      });
      carouselCurrent = carouselNext;
    }
  }

  function navRight(){
    if(!carouselAnimating){
      carouselAnimating = true;
      var carouselNext = getCarousel(1);
      carouselNext.animate({left: windowSize}, 0);
      carouselNext.animate({left: 0}, 400);
      carouselCurrent.animate({left: '-=' + windowSize}, 400, function(){
        carouselAnimating = false;

        if(!mobile){
          clearTimeout(carouselTimer);
          carouselTimer = setTimeout(navRight, carouselDelay);
        }
      });
      carouselCurrent = carouselNext;
    }
  }

  function fadeCarousel(){
    getCarousel(0).fadeOut();
    getCarousel(1).fadeIn();
  }

  function getCarousel(dir){
    var index = carouselIndex + dir;
    index = (index > carouselLength)? 1: index;
    index = (index == 0)? carouselLength: index;
    carouselIndex = index;
    return $(carouselItems[index - 1]);
  }

  function resize(){
    windowSize = $(window).width();
  }

  if(type == 'carousel'){
    if(carouselLength > 1){
      $('.hero__nav').show();
      $('.hero__nav-left').click(navLeft);
      $('.hero__nav-right').click(navRight);

      carouselItems.each(function(index){
        $(this).animate({left: index * windowSize}, 0);
      });

      if(!mobile){
        clearTimeout(carouselTimer);
        carouselTimer = setTimeout(navRight, carouselDelay);
      }
    }
  }else{
    setInterval(fadeCarousel, 5000);
  }

  $(window).resize(resize);
};

module.exports = carouselFunctionality;