var $ = require('jquery');
require('jqueryui');
require('validate');

var MyComponent = require('./components/component');
var menuFunctionality = require('./components/menu');
var sseFunctionality = require('./components/sse');
var ceFunctionality = require('./components/ce');
var carouselFunctionality = require('./components/carousel');
var notificationFunctionality = require('./components/notification');
var sidebarFunctionality = require('./components/sidebar');
var speakersBureauFunctionality = require('./components/speakersBureau')
var speakersBureauCalculator = require('./components/sb-event-calculator');
var speakersBureauModals = require('./components/sb-modals');
var formValidate = require('./components/formValidate');

window.MyComponent = MyComponent;

$(function() {
	menuFunctionality();
	sseFunctionality();
	ceFunctionality();
	carouselFunctionality('.content__slide', 'carousel');
	notificationFunctionality('.header__notification', 'carousel');
	sidebarFunctionality();
	speakersBureauFunctionality();
	speakersBureauCalculator();
	speakersBureauModals();
	formValidate();
});
