var formValidate = function() {

  var attrs = {};

  $(".speakers-bureau__request-pod").replaceWith(function () {
    var currentId = $(this).attr('id');
    return $("<form id='" + currentId + "' class='speakers-bureau__request-pod'/>", attrs).append($(this).contents());
  });

  function validateInputs(current) {
    var requestSection = $(current).parents().next().attr('id');
    var nextRequestSection = '#' + requestSection;
    var currentFormSection = $(current).closest('.speakers-bureau__request-pod').attr('id');
    currentFormSection = '#' + currentFormSection;

    var validator = $(currentFormSection).validate()
    var forms = validator.form();

    if ($(nextRequestSection).hasClass('speakers-bureau__request-saved')) {
      var editRequestSection = $(nextRequestSection).next().attr('id');
      editRequestSection = '#' + editRequestSection;

      if (forms === true) {
        if ($(editRequestSection).hasClass('speakers-bureau__request-saved')) {
          editRequestSection = $(editRequestSection).next().attr('id');
          editRequestSection = '#' + editRequestSection;

          $('.speakers-bureau__request-pod-accordion').hide();
          $(editRequestSection).find('.speakers-bureau__request-pod-accordion').show();
        } else {
          $('.speakers-bureau__request-pod-accordion').hide();
          $(editRequestSection).find('.speakers-bureau__request-pod-accordion').show();
        }

        requestSection = $(nextRequestSection).prev().attr('id');
        requestSection = '#' + requestSection;
        $(requestSection).addClass('speakers-bureau__request-saved');
      }
    } else {
      if (forms === true) {
        $('.speakers-bureau__request-pod-accordion').hide();
        $(nextRequestSection).find('.speakers-bureau__request-pod-accordion').show();

        requestSection = $(nextRequestSection).prev().attr('id');
        requestSection = '#' + requestSection;
        $(requestSection).addClass('speakers-bureau__request-saved');
      }
    }
  }

  $(document).on('click', '.speakers-bureau__request-pod-accordion input[type="button"]', function() {
    var current = $(this);
    validateInputs(current);
  });

};

module.exports = formValidate;
