var menuFunctionality = function() {

  var debounce = false;
  var debouncing = false;
  var debounceTimeout;
  var collapsed = false;
  var mobile = /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  var iPad = /iPad/i.test(navigator.userAgent);
  var headerToggle = $('.fuel-header-menu');
  var footerToggle = $('.fuel-footer-title');
  var tabToggle = $('.tab__toggle');
  var winnerToggle = $('.fuel-subheader-content-desktop');
  var winner = false;
  var winnerInterval;

  function scrollMove(){
    if(debounce){
      if(!debouncing){
        debouncing = true;
        clearTimeout(debounceTimeout);
        debounceTimeout = setTimeout(function(){
          debounce = false;
          debouncing = false;
          var maxTop = Math.max($('html').scrollTop(), $('body').scrollTop());
          if(maxTop == 0 && collapsed){
            collapsed = false;
            $('.fuel-header-menu').toggleClass('fuel-header-hidden');
            $('.fuel-subheader-content').slideDown();
          }else{
            if(!collapsed){
              collapsed = true;
              $('.fuel-header-menu').toggleClass('fuel-header-hidden');
              $('.fuel-subheader-content').slideUp();
            }
          }
        }, 250);
      }
    }else{
      debounce = true;
      var maxTop = Math.max($('html').scrollTop(), $('body').scrollTop());
      if(maxTop == 0 && collapsed){
        collapsed = false;
        $('.fuel-header-menu').toggleClass('fuel-header-hidden');
        $('.fuel-subheader-content').slideDown();
      }else{
        if(!collapsed){
          collapsed = true;
          $('.fuel-header-menu').toggleClass('fuel-header-hidden');
          $('.fuel-subheader-content').slideUp();
        }
      }
    }
  }

  var winHeight = $(window).height(), 
      docHeight = $(document).height(),
      progressBar = $('progress'),
      max, value;
  max = docHeight - winHeight;
  progressBar.attr('max', max);

  $(document).scroll(function(){
     value = $(window).scrollTop();
     progressBar.attr('value', value);
  });

  $('.header__notification-close').click(function() {
    $('.header .slider.autoplay').hide();
    $('.footer').css('top','100vh');
  });

  var fixedNavbar = $('.sse-article__sidebar-nav').width();

  $(window).scroll(function() {
    var article = $(window).scrollTop();
    var contentMargin = fixedNavbar + 2;

    var windowWidth = $(window).width();
    if (windowWidth > 768) {

      if (article > 322) {
        if($('.header__menu-rollup').length > 0) {
          $('.header__menu').addClass('rollup').css('opacity','0');
          $('.header__menu-rollup').show().addClass('fadeInDown').removeClass('fadeOut');
        }
        $('.sse-article__sidebar-nav,.sse-article__sidebar-share').addClass('sidebar-fixed');
        $('.sse-article__sidebar-nav').css('width', fixedNavbar);
        $('.sse-article__content').css('margin-left', contentMargin);
      } else {
        if($('.header__menu-rollup').length > 0) {
          $('.header__menu').removeClass('rollup').css('opacity','1');
          $('.header__menu-rollup').removeClass('fadeInDown').addClass('fadeOut');
        }
        $('.sse-article__content').css('margin-left', '0');
        $('.sse-article__sidebar-nav,.sse-article__sidebar-share').removeClass('sidebar-fixed');
        $('.sse-article__sidebar-nav').css('width', '20.8333%');
      }
    }
    
  });

  $('.header__menu-rollup-icon').click(function() {
    $('.header__menu').show().css('opacity','1');
    $('.header__menu-rollup').hide();
  });

  $('img.menu-mobile-btn-open').click(function() {
    $('.header__menu-mobile.menu-slidedown').animate({'top':'0'}, 500);
  });
  $('img.menu-mobile-btn-close').click(function() {
    $('.header__menu-mobile.menu-slidedown').animate({'top':'-100vh'}, 500);
    $('.nation-select-mobile-options').css('display', 'none');
  });

  $('.header__menu-profile-mobile').click(function() {
    var profileOpacity = $('.header__menu-profile-mobile a').css('opacity');

    if (profileOpacity === '0') {
      $('.header__menu-profile-mobile a').css({'opacity':'1','height':'auto'});
      $('.nation-select-mobile').css('visibility','hidden');
    } else {
      $('.header__menu-profile-mobile a').css({'opacity':'0','height':'0'});
      $('.nation-select-mobile').css('visibility','visible');
    }
    
  });

  var nationOptions = $('.nation-select-mobile-options').height();
  var nationSelected = $('.nation-mobile-selected').outerHeight();
  $('.nation-select-mobile-options').css('bottom', nationOptions + nationSelected);

  $('.nation-mobile-selected').click(function() {
    var nationDisplay = $('.nation-select-mobile-options').css('display');
    if (nationDisplay === 'none')  {
      $('.nation-select-mobile-options').css('display', 'block').animate({opacity:1}, 500);
    } else {
      $('.nation-select-mobile-options').animate({opacity:0}, 500).css('display', 'none');
    }
      
  });


  function toggleFooter(){
    if($(this).next().css('display') == 'none'){
      $('.fuel-footer-expand').show();
      $('.fuel-footer-collapse').hide();
      $('.fuel-footer-list-content').slideUp();
      $(this).next().slideToggle();
      $(this).find('.fuel-footer-expand').hide();
      $(this).find('.fuel-footer-collapse').show();
    }else{
      $('.fuel-footer-expand').show();
      $('.fuel-footer-collapse').hide();
      $('.fuel-footer-list-content').slideUp();
    }
  }

  function toggleTab(){
    if($(this).hasClass('tab__toggle-selected')){
      $(this).removeClass('tab__toggle-selected');
      $(this).next().slideToggle();
    }else{
      $(this).addClass('tab__toggle-selected');
      $(this).next().slideToggle();
    }
  }

  function closeDrawer(){
    if(!winner){
      $('.fuel-subheader-content-drawer').slideUp();
      clearInterval(winnerInterval);
    }
  }

  if(mobile){
    $(footerToggle).click(toggleFooter);
  }else{
    $(window).bind('scroll.header', scrollMove);

    $(winnerToggle).hover(function(){
      $('.fuel-subheader-content-drawer').slideDown();
      winner = true;
    },function(){
      winner = false;
      clearInterval(winnerInterval);
      winnerInterval = setInterval(closeDrawer, 500);
    });
  }

  $(tabToggle).click(toggleTab);

  $('.header__menu-profile-icon').hover(
    function() {
      $('.header__menu-profile-dropdown').slideDown();
    },
    function() {
      $('.header__menu-profile-dropdown').slideUp();
    }
  );
};

module.exports = menuFunctionality;