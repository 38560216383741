var sidebarFunctionality = function() {

	var nationHeight = $('.nation-select-options').height();
	$('.nation-select-options').css('bottom', - nationHeight);

  	$('.nation-selected').click(function() {
	  	var optionsPosition = $('.nation-select-options').css('bottom');
	  	if (optionsPosition === '0px') {
	  		$('.nation-select-options').animate({bottom: - nationHeight}, 'slow');
	  	} else {
	  		$('.nation-select-options').animate({bottom: '0'}, 'slow');
	  	}  	
  	});
  	
};

module.exports = sidebarFunctionality;