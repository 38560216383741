var speakersBureauCalc = function() {
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });

  var total = '';
  var dataMathCalculate = function() {
    total = 0;
    $('#event-details [data-math]').each(function() {
      var sponsorInput = $(this);

      if (sponsorInput.is('[data-math-add]')) {
        var sponsorInputValue = sponsorInput.val();
        
        if (sponsorInputValue != '') {
          total += parseFloat(sponsorInputValue);
        } else {
          sponsorInputValue = 0;
          total += parseFloat(sponsorInputValue);
        }
      }
      if (sponsorInput.is('[data-math-multiply]')) {
        var sponsorMultiplierValue = $(sponsorInput.data('math-multiply'));
        sponsorMultiplierValue = sponsorMultiplierValue.val();
        sponsorMultiplierValue = sponsorMultiplierValue * sponsorInput.val();
        total += parseFloat(sponsorMultiplierValue);  
      }
    });
    // Not supported in Edge/IE11 :(
    // $('.speakers-bureau__request-sponsor-total').html(total.toLocaleString("en-US", {style: "currency", currency: "USD", minimumFractionDigits: 2}));
    $('.speakers-bureau__request-sponsor-total').html(formatter.format(total));
  };

  $(document).on('focusout', '[data-math]', function () {
    dataMathCalculate();
  });
  
  $(document).on('click', '[data-cta-remove]', function () {
    dataMathCalculate();
  });
};

module.exports = speakersBureauCalc;


