var ceFunctionality = function() {

  	$(window).on('load resize', function () {
      if($('.ce__catalog-list-course-credit-data').length > 0 && $('.ce__catalog-list-course-credit-header').css('display') !== 'none') {
    	var courseExpWidth = $('.ce__catalog-list-course-credit-header p:last-of-type').width();
        var windowWidth = $(window).width();
        if (windowWidth > 768) {
        	$('.ce__catalog-list-course-credit-data p:last-of-type').width(courseExpWidth);
        } else {
        	$('.ce__catalog-list-course-credit-data p:last-of-type').width('auto');
        }
      }
  	});

    $('.ce__register-container select').click(function() {
      $(this).css('color','#1d1d1d');
    });
	
	
	//ON MOBILE SET
	function extractHostname(url) {
		var hostname;
		//find & remove protocol (http, ftp, etc.) and get hostname

		if (url.indexOf("//") > -1) {
			hostname = url.split('/')[2];
		}
		else {
			hostname = url.split('/')[0];
		}

		//find & remove port number
		hostname = hostname.split(':')[0];
		//find & remove "?"
		hostname = hostname.split('?')[0];

		return hostname;
	}
	
	var allLinks = document.querySelectorAll('.mobileLinks a');
	var url = window.location.href;
	var host = extractHostname(url);
	var options = document.querySelectorAll('.sse__content-nav-mobile-select select option');
	var path = url.replace("https://", "").replace("http://", "").replace(host, "").toLowerCase();
	
if(document.querySelector('.sse__content-nav-mobile-select select')){
	 for(var i = 0; i < allLinks.length; i++){
		if(allLinks[i].getAttribute('href').toLowerCase() == path){
			var text = allLinks[i].innerText.toLowerCase();
			
						 
			 for(var d  = 0; d < options.length; d++){
				if(options[d].value.toLowerCase() == text){
					console.log("test:: ", options[d].value);
					options[d].setAttribute('selected', 'selected');
				}
			}
		}
	 }
	
	
	//ON MOBILE CHANGE
	
	
	
	document.querySelector('.sse__content-nav-mobile-select select').addEventListener('change', function(e){ 
	   
		 var textValue = e.target.value;

		 for(var i = 0; i < allLinks.length; i++){
			if(allLinks[i].innerText == textValue){
				var url = allLinks[i].getAttribute('href');
				window.location.href = url;
			}
		 }
	});
	}
	
};

module.exports = ceFunctionality;