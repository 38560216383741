var sseFunctionality = function() {

    var topicNumber = 0;

  	$('.sse-article__content h2').each(function() {
  		var topicText = $(this).text();

      topicNumber = topicNumber + 1;
      var topicAnchor = 'articleTopic_' + topicNumber;
      $(this).addClass('sse-article__header-option ' + topicAnchor);
	  	if (topicText.indexOf('INTRODUCTION') >= 0 || topicText.indexOf('Introduction') >= 0) {
        $(this).before('<div id="recommendSidebar"></div><a class="see-article__scroll-link" name="' + topicAnchor + '"></a>');
        $('#sse-article__header-options').append('<li><div class="sse-article__header-options-link-wrapper"><a class="' + topicAnchor + '" href="#' + topicAnchor + '">' + topicText.toLowerCase() + '</a></div></li>');
	  	} else {
        $(this).before('<a class="see-article__scroll-link" name="' + topicAnchor + '"></a>');
        $('#sse-article__header-options').append('<li><div class="sse-article__header-options-link-wrapper"><a class="' + topicAnchor + '" href="#' + topicAnchor + '">' + topicText.toLowerCase() + '</a></div></li>');
      }
  	});

    $('.research-details__content strong').each(function() {
      $(this).addClass('research-details__content-header');
    });

    $('.research-details__content p').each(function() {
      if ($(this).css('font-weight') === 'bold') {
        $(this).addClass('research-details__content-header');
      }
    });

  	$(window).on('load', function () {
      $('.gssi-recommended-sidebar').appendTo('#recommendSidebar');
      var videoPlacement = 'h2.articleTopic_3';
      $(videoPlacement).before('<div id="articleVideoPod"></div>');
      $('.about-main-img-pod').appendTo('#articleVideoPod');
      var paragraphTotal = $('.sse-article__content h2').length;
      var paragraphId = paragraphTotal - 1;
      var paragraphSelection = 'h2.articleTopic_' + paragraphId;
      $(paragraphSelection).before('<div id="earnCreditLink"></div>');
  		$('.gssi-earn-credit-link').appendTo('#earnCreditLink');
  	});

    $(window).scroll(function() {
      var scrollTop = $(window).scrollTop();

      $('.see-article__scroll-link').each(function() {
        var scrollHeaderPostion = $(this).position().top;
        var articleTopicScroll = $(this).attr('name');
        var articleTopicActive = '#sse-article__header-options li a.' + articleTopicScroll;

        if (scrollTop > scrollHeaderPostion) {
          $('#sse-article__header-options li a').removeClass('active');
          $(articleTopicActive).addClass('active');
        }
      });
    });

    $('.gssi-popout').click(function() {
      $('.gssi-newsletter').css('display','flex');
      $('body').css('overflow','hidden');
    });

    $('.gssi-newsletter-form img').click(function() {
      $('.gssi-newsletter').css('display','none');
      $('body').css({'overflow-y':'inherit','overflow-x':'hidden'});
    });

    $('.gssi-newsletter-form-pod input').each(function() { 
      $this = $(this);
      $label = $('label[for="'+ $this.attr('id') +'"]');
      $label.hide();
      if ($label.length > 0 ) {
         $(this).attr('placeholder', $label[0].textContent);
      }
    });

    $('.gssi-newsletter-form-pod input[type="submit"]').after('<i class="icon-right"></i>');

};

module.exports = sseFunctionality;